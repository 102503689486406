@media screen and (max-width: 767px){
    .upper-footer > div {
        margin-bottom: 15px;
    }
    .fwrapper .small-icon{
        order: 1;
        margin-right: 15px;
    }
    .fwrapper .flex-1{
        order: 2;
        margin-right: 0;
    }
    .fis-main .fwrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 0;
    }
    .fis-main .fwrapper img{
        margin-bottom: 15px;
    }
    .fis-main .fwrapper p{
        font-weight: 700;
        text-align: center;
    }
    .copyright{
        flex-direction: column;
    }
    .copyright>div{
        margin-bottom: 15px;
    }
    .lower-footer{
        flex-direction: column;
    }
    .lower-footer .social-area{
        justify-content: center;
        margin-bottom: 15px;
    }
    .lower-footer .browsing ul{
        justify-content: center;
        flex-wrap: wrap;
    }   
    .fis-designer{
        text-align: center;
        line-height: 2;
    }
    .header-info{
        flex-direction: column;
    }
    .header-info .area-btns{
        margin: 7px 0;
    }
    
    .header-menu.mobile{
        display: block;
    }
    
    .header-menu.desktop{
        display: none;
    }
    .prod-explain{
        flex-direction: column;
    }
    .prod-explain .data-start{
        order: 2;
    }
    .prod-explain .data-image{
        order: 1;
        margin-bottom: 25px;
    }
    .prod-explain .data-image img{
        width: 200px;
    }    
    .hero-big-tile{
        padding: 0px;
    }
    .info-titles h4 {
        font-size: 1.5rem;
        margin: .2rem 0;
    }
    .info-titles > p {
        font-size: 1.2rem;
        margin: .3rem 0;
    }
    .welcome-content {
        padding: 25px 0;
        display: flex;
    }
    .welcome-content img.img{
        width: 100%;
        display: block;
        margin-bottom: 24px;
    }
    .img-mobile-app {
        height: 300px;
        position: static;
    }
    .welcome-content p {
        font-size: .8rem;
        margin-bottom: 7px;
    }
    .hero-big-title {
        padding: 0;
    }
    .info-titles .card-portal{
        width: 100%;
    }
    .info-web{
        display: none;
    }
    .quick-inner h2{
        font-size: 1.5rem;
    }
    .quick-inner h3{
        font-size: .9rem;
    }
    .buttons{
        display: flex;
        flex-direction: column;
    }
    .buttons .btn-lg{
        font-size: .8rem;
        margin-bottom: .5rem;
    }
    .area-btns a.btn{
        font-size: .7rem;
    }
    .fis-offices .row .office-cover{
        margin-bottom: 1rem;
        border: 1px solid #d64427;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 7px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .office-cover div.img {
        padding-right: 0;
    }
    .fis-offices > h4{
        border-bottom: none;
    }
    .office-cover img {
        border-radius: 0px;
    }
    /* .home-mobile .display-text{
        order: 2;
    }
    .col-md-6.mobile-display{
        order: 1;
    } */
}
@media (max-width: 789px){
    .boddy-inner {
        padding: 10px 0;
        flex-direction: column !important;
    }
    .boddy-inner .left,
    .boddy-inner .right{
        padding: 0;
    }
}
@media (min-width: 544px){
    .text-block-paragraph .text-block-paragraph p {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
    }
}
@media screen and (min-width: 320px){
    .lx-button {
        font-size: calc(.85rem + .25vw);
    }
}
@media (min-width: 544px){
    .lx-button {
        width: auto;
    }
}
@media (min-width: 544px) and (max-width: 767px){
    .text-block-paragraph p .lx-button {
        width: calc(50% - (.5rem / 2));
    }
}
@media (min-width: 544px){
    .text-block-paragraph p .lx-button {
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }
}
@media (min-width: 544px) and (max-width: 767px){
    .text-block-paragraph p .lx-button:nth-child(2n+1) {
        margin-left: 0!important;
    }
}
@media (max-width: 881px){
    .some-products-content {
        padding: 25px 0px;
    }
    .products-container{
        display: grid;
        grid-gap: 5px;
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 511px){
    .some-products-content {
        padding: 25px 0px;
    }
    .products-container{
        display: grid;
        grid-gap: 5px;
        grid-template-columns: 1fr;
    }
    .products-container .prod-preview{
        text-align: center;
        padding: 1rem;
        flex-direction: row;
        justify-content: center;
    }
    .product-icon {
        width: 18px;
        height: 18px;
        margin-right: .5rem;
    }
    .some-products-content h2{
        font-size: 1.2rem;
    }
}
@media (max-width: 409px){
    .offer-type-container .offer-box {
        flex-basis: 100%;
    }
}
@media (max-width: 539px){
    .boddy-inner .person .person-profile{
        flex-basis: 100%;
    }
}
@media (max-width: 525px){
    .board-member{
        flex-direction: column;
    }
    .board-member .b-person .person-profile{
        flex-basis: 100%;
    }
    .board-member .b-person {
        margin-right: 0;
    }
    .board-member .b-details p{
        padding-left: .5rem;
    }
}
@media (max-width: 580px){
    .offer-box{
        padding: .3rem;
    }
}
@media (max-width: 982px){
    .office-cover{
        flex-direction: column;
    }
    .fis-office-details{
        margin-top: 1rem;
    }
    .fis-offices>.row{
        padding-top: 1rem;
    }
    .fis-offices > h2,
    .fis-offices > h4{
        text-align: center;
    }
}