.wise-btn{
    padding-left: 1rem;
    padding-right: 1rem;
    border: 1px solid;
    border-radius: 3.33333px;
    transition: background-color .15s ease-out;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: .5rem 1rem;
    font-family: Roboto,sans-serif;
    line-height: initial;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    position: relative;
}
.img{
    width: 100%;
}
.wise-btn.primary{
    background-color: #1565c0;
    border-color: #104d92;
    border-bottom-color: #041324;
    color: #fff;
}
a.wise-btn{
    text-decoration: none;
}
.text-faded {
    color: #555;
}
.font-p-large{
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 28px;
}
.box-flex{
    display: flex;
}
.box-flex.column{
    flex-direction: column;
}
.box-flex.center{
    justify-content: center;
    align-items: center;
}
.box-flex.column.bottom{
    justify-content: flex-end;
}
.box-flex.streched{
    justify-content: center;
    align-items:stretch;
}
.list-none{
    list-style: none;
    padding: 0;
    margin: 0;
}
.list-none.flex{
    display: flex;
}
.main-menu>li{
    margin-right: 10px;
}
.main-menu li:last-child{
    margin-right: 0;
}
.fis-website p{
    margin: 0;
}
.flex-1{
    flex: 1;
}

.header-banner{
    background-color: #d64427;
}
.header-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px;
    font-size: .8em;
}
.header-info .btn{
    border: 1px solid #f7aa04;
    border-radius: 12px;
    padding: 3px 6px;
    background-color: #f0f8ff;
}
.header-info .btn:hover{
    color: #f7aa04;
}
.header-info .area-info{
    color: #fff;
}
.header-menu{
    background: white;
    border-bottom: 1px solid #e3ecf5;
}
.header-menu.mobile{
    display: none;
    position: relative;
    padding: 15px 10px;
    background-color: #e3ecf5;
    position: relative;
}
.header-menu.mobile .btn-bar-cancel,
.header-menu.mobile .mobile-menu{
    display: none;
}
.header-menu.mobile.isMenuVisible .btn-bar-menu{
    display: none;
}
.header-menu.mobile.isMenuVisible .btn-bar-cancel,
.header-menu.mobile.isMenuVisible .mobile-menu{
    display: block;
}
.header-menu.mobile .mobile-menu{
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    top: 100%;
    z-index: 1;
    background-color: #d5e3f1;
}
.mobile-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mobile-bar img{
    height: 50px;
}
.mobile-bar .mobile-menu-activator{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
}
.mobile-menu-activator i.fas{
    font-size: 30px;
}
.mobile-menu .card-body{
    padding: 0 !important;
}
.mobile-menu .card-body .list-group-item a{
    display: block;
    position: relative;
}
.mobile-menu .card-body .list-group-item a:after{
    content: '➤';
    position: absolute;
    right: 0;    
}
.mobile-menu .list-group-item a span{
    color: #f7aa04;
    font-weight: bold;
}


.main-nav{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
}
.main-nav .logo{
    width: 175px;
}
.main-menu > li{
    display: flex;
    align-items: center;
}
.main-menu > li:hover{
    background-color: #e3ecf5;
}
.main-menu > li:hover > a{
    color: #fd9800;
}
.main-menu > li > a{
    text-transform: uppercase;
    text-decoration: none;
    padding: 0 8px;

    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.main-menu > li:hover .submenu{
    display: block;
}
.main-menu > li .submenu{
    display: none;
    position: absolute;
    z-index: 1;
    top: 100%;
    background-color: #e3ecf5;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 10px 0;
}
.main-menu > li > .submenu > li > a{
    padding: 8px 15px;
    display: block;
    text-decoration: none;
}
.main-menu > li .submenu > li:hover > a{
    background-color: #f7aa04;
    color: #fff;
}
.main-menu .products .submenu{
    width: 100%;
    left: 0;
    padding: 15px 25px;
}
.product-list li a {
    padding: 4px 4px;
    position: relative;
    text-decoration: none;
    padding-left: 35px;
    font-size: 1rem;
}
.product-list li a:hover{
    color: #f7aa04;
}
.product-list li a:hover:before{
    color: #000;
}
.product-list li a::before{
    content: '⦾';
    position: absolute;
    left: 0;
}
h4.prod-grp-title{
    font-size: 1.1rem;
    margin-bottom: 1rem;
}
h4.prod-grp-title span{
    color: #f7aa04;
    font-weight: bold;
}
.small-contact{
    display: flex;
    flex-direction: column;
    color: #12161a;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    border-radius: 5px;
    padding: .6rem 1.3rem;
    max-width: 100%;
    margin-top: 2rem;
}
.small-contact .contact-title{
    font-family: "Roboto Slab",sans-serif;
    font-weight: 400;
    line-height: normal;
    vertical-align: top;
    display: flex;
    align-items: center;
    font-size: 1.6em;
}
.small-contact .contact-body{
    padding: 1rem 0;
}
.small-contact .contact-footer{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
}
.small-contact .contact-footer .footer-inside{
    padding-top: 0;
    flex-wrap: wrap;
    padding: 1rem 0;
}

.image-holder-down{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.image-holder-down img{
    max-width: 100%;
}






















.fwrapper{
    display: flex;
}

footer:not(.blockquote-footer){
    padding-top: 4em;
    background-color: #e3ecf5;
}

.lower-footer{
    display: flex;
    padding: 15px 0;
    justify-content: space-between;
}
.social-area{
    display: flex;
}
.social-area .social{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 6px;
    background-color: rgb(117 165 207 / 1);
    margin-right: 15px;
    opacity: 0.5;
}
.social-area .social:hover{
    opacity: 1;
}
.social-area .social svg{
    fill: #ffffff;
}
.social-area .social img,
.social-area .social svg{
    width: 25px;
    height: 25px;
    transition-property: width,height;
    transition-duration: .5s;
    transition-delay: .1s;
}
.social-area .social:hover img,
.social-area .social:hover svg{
    width: 40px;
    height: 40px;
}
.fis-main .fwrapper{
    display: block;
    padding-right: 25px;
}
.fis-main img{
    width: 100%;
    max-width: 250px;
}
.small-icon{
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #d64427;
    display: flex;
    align-items: center;
    justify-content: center;
}
.copyright{
    background-color: #d64427;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .6em 2em;
    color: #fff;
    box-shadow:  -1px 1px #d64427,
         -2px 2px #d64427,
         -3px 3px #d64427,
         -4px 4px #d64427,
         -5px 5px #d64427;
}
.copyright .owner{
    font-weight: bold;
}
.small-icon img{
    display: block;
    width: 30px;
    height: 30px;
}
.fwrapper>div:first-child{
    margin-right: 5px;
}
.fwrapper .content p>span{
    display: none;
}
.fwrapper .content p{
    margin-bottom: .2rem;
}
.fis-email .fwrapper{
    min-height: 150px;
}
.fwrapper .flex-1 > h4{
    margin-bottom: .4em;
}

.copyright a{
    font-weight: bold;
    color: #fff;
    text-decoration: underline;
}

.browsing ul{
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.browsing ul li:not(:last-child){
    margin-right: 15px;
}
.browsing ul li a{
    text-decoration: none;
    padding: 5px 8px;
}
.fis-designer a{
    text-decoration: none;
}
.fis-designer a:hover{
    text-decoration: underline;
}

.data-title{
    background-color: #f7aa04;
    padding: 25px 0;
}
.data-title-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.data-title-content h4{
    font-size: 1.8em;
    color: #fff;
}
.data-title-content img{
    width: 55px;
}
.data-content-content{
    /* display: flex; */
    padding: 25px 0;
}
.prod-explain{
    display: flex;
}
.prod-explain > * {
    flex: 1;
}
.data-image img{
    width: 150px;
}
.prod-details{
    margin-top: 1.8em;
}
.cookies p {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.7px;
    line-height: 28px;
}
.home-hero{
    background-image: url('src/assets/images/range_rover_wide_white.jpg');
    padding-bottom: 2.5rem;
}
.home-mobile{
    background-image: url('src/assets/images/office_black_mobile_app.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 2.5rem;
}

.hero-big-title{
    padding: 25px;
    display: flex;
}
.hero-big-title > div{
    flex: 1; 
}
.info-titles > h4,
.info-titles > p{
    color: white;
}
.info-titles h4{
    font-size: 3rem;
    margin: .5rem 0;
}
.info-titles > p{
    font-size: 1.6rem;
    margin: .6rem 0
}
.welcome-area{
    background-color: #e3ecf5;
}
.welcome-content{
    padding: 25px;
    display: flex;
}
.welcome-content h5{
    font-size: 1.8rem;
}
.welcome-content p{
    font-size: 1.2rem;
    margin-bottom: 15px;
}
.welcome-content img{
    width: 80%;
}
.info-titles .card-portal{
    width: 18rem;
}
.some-products{
    background-color: #fff;
    background-image: linear-gradient(to bottom right,#903 30%,#602 100%);
    border-top: 1px solid #670022;
    border-bottom: 1px solid #670022;
}
.text-block-title {
    margin-bottom: 1rem;
    width: 100%;
}
.text-block-paragraph{
    width: 100%;
}
.lx-button {
    font-size: .85rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: .5rem 1rem;
    font-family: Roboto,sans-serif;
    line-height: initial;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    position: relative;
    border-radius: 3.33333px;
    color: #fff;
}

.text-block-paragraph p .lx-button {
    background-color: rgba(153,0,51,.5);
    border: 1px solid #670022!important;
    transition: box-shadow .3s ease-in-out,-webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out,box-shadow .3s ease-in-out;
    transition: transform .3s ease-in-out,box-shadow .3s ease-in-out,-webkit-transform .3s ease-in-out;
}
.lx-icon {
    height: 1rem;
    width: 1rem;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    fill-opacity: 0;
}
.text-block-paragraph p .lx-button .lx-icon {
    margin-right: .5rem;
}
.text-block-title{
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
    color: #fff;
}
.some-products-content{
    padding: 25px;
}
.prod-preview{
    border-radius: 3.33333px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #99003380;
    border: 1px solid #670022!important;
    transition: box-shadow .3s ease-in-out,-webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out,box-shadow .3s ease-in-out;
    transition: transform .3s ease-in-out,box-shadow .3s ease-in-out,-webkit-transform .3s ease-in-out;
    text-decoration: none;
    color: #fff;
}
.product-icon{
    width: 44px;
    height: 44px;
}
.prod-preview.lx-button:hover{
    transform: scale(1.1);
    background-color: #903;
    box-shadow: 0 10px 40px 15px rgb(0 0 0 / 10%);
    z-index: 1;
    text-decoration: none;
    color: #fff;
}
.products-container{
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.products-container .prod-preview{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #fff;
}
.products-container .prod-preview:hover{
    transform: scale(1.1);
    background-color: #903;
    box-shadow: 0 10px 40px 15px rgb(0 0 0 / 10%);
    z-index: 1;
    text-decoration: none;
}
.quick-decisions{
    background-color: #77aaff;
    background-image: url('src/assets/images/wisdom-b.jpg');
}
.quick-decisions .quick-inner{
    padding: 35px 0;
    text-align: center;
    color: #fff;
}
.quick-inner h2{
    font-size: 2.3rem;
}
.quick-inner h3{
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
}

.quick-inner .proverb{
    font-size: 1.3rem;
}
.quick-inner .proverb span{
    display: inline-block;
    font-size: 4rem;
    margin-top: 15px;
}
.buttons a.btn i{
    margin-left: 15px;
}

.content-header{
    background-image: url('src/assets/images/sky_blue.jpg');
    background-repeat: repeat;
    background-size: cover;
}
.header-inner{
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-inner h3{
    font-size: 1.8rem;
    color: #fff;
}
.header-inner img{
    width: 75px;
}
.content-body{
    background-color: #f5e4d9;
    padding-bottom: 2rem;
}
.boddy-inner{
    padding: 25px 0;
    display: flex;
}
.boddy-inner .left{
    flex: 2;
    padding: 15px 1rem;
}
.boddy-inner .right{
    flex: 1;
    padding: 15px 1rem;
}
.boddy-inner p{
    margin-bottom: 1rem;
}
.main-color{
    color: #d64427;
}
.boddy-inner .left h4{
    font-size: 1.2rem;
    color: #d64427;
}
.profile-card .card-title{
    color: #d64427;
}
.boddy-inner .person-profile{
    flex-basis: 48%;
    border: 1px solid #d64427;
    padding-top: 4px;
    border-radius: 8px;

}



.profile-offer{
    margin-bottom: 20px;
}
.offer-type-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.offer-type-container .offer-box{
    flex-basis: 45%;
}
.offer-box{
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    padding: 1rem;
    background-color: #fff;
    margin-bottom: 1.3rem;
    background-clip: border-box;
}
.boddy-inner .left .offer-box h4{
    color: #d64427;
    font-size: 1rem;
    position: relative;
    padding-top: .4rem;
    border-top: 4px  solid #06b1f5;
}
.boddy-inner .left .offer-box h4:after{
    content: ' ';
    position: absolute;
    left: 0;
    top: 100%;
    width: 40px;
    display: block;
    height: 2px;
    background-color: #06b1f5;
    margin-top: 3px;
}
.offer-box ul{
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 1.6rem;
    
}
.offer-box ul li{
    margin: 5px 0;
    margin-left: 20px;
    position: relative;
}
.offer-box ul li::before{
    content: '➤';
    position: absolute;
    left: -20px;
}
.list-right-pointers{
    list-style: none;
    padding: 0;
    margin: 0;
}
.list-right-pointers li{
    margin: 5px 0;
    margin-left: 20px;
    position: relative;
}
.list-right-pointers li::before{
    content: '➤';
    position: absolute;
    left: -20px;
}

.boddy-inner .person{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.boddy-inner .person .person-profile{
    background-color: #fff;
    border: 1px solid #d64427;
    flex-basis: 49%;
    margin-bottom: 3rem;
}
.person-profile .img-profile{
    text-align: center;
}
.person-profile .user-names{
    background-color: #d64427;
}
.person-profile .user-names p{
    margin-bottom: 0 !important;
    text-align: center;
    color: #fff;
}
.person-profile .user-names p.name{
    padding-top: .4rem;
    font-size: 1rem;
    font-weight: bold;
}
.person-profile .user-names p.job{
    font-size: .8rem;
    padding-bottom: .4rem;
}
.user-details p{
    padding: 1rem;
    font-size: 1rem;
}

.user-details p span.short-name,
span.short-name{
    color: #d64427;
}
.board-member{
    display: flex;
    flex-direction: row;
    border: 1px solid #d64427;
    border-radius: .25rem;
    background-color: #fff;
    margin-bottom: 2rem;
}
.board-member .b-person{
    flex: 1;
    margin-right: 1rem;
}
.board-member .b-details{
    flex: 2;
}
.board-member .b-details p{
    padding-right: .5rem;
}
.board-member .b-details p:first-child{
    margin-top: 1rem;
}
.board-member .b-details p:last-child{
    margin-bottom: 1rem;
}
.boddy-inner .b-person .person-profile {
    /* flex-basis: 48%;
    border: 1px solid #d64427;
    padding-top: 4px;
    border-radius: 8px; */
    border: none;
    border-right: 1px solid #d64427;
    border-radius: 0px;
}
.boddy-inner .b-person .person-profile .img-profile img{
    min-width: 258px;
}
.header-inner h3 > span{
    color: #d64427
}

.insur-products .left p{
    font-size: 1.1rem;
}
.quote-req textarea{
    resize: none;
    height: 120px;
}
.request-quote{
    background-color: #d64427;
    color: #fff;
    padding: 20px;
}
.quote-req .btn{
    background-color: #000;
    border: none;
    color: #fff;
}
#map{
    height: 400px;
    /* The height is 400 pixels */
    width: 100%;
    /* The width is the width of the web page */
}
.form-container h3{
    font-size: 1.5rem;
}
.form-container h6{
    font-size: .75rem;
}
.w-card{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e3eaef;
    border-radius: .25rem;
    
    border: none;
    -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
    box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
    margin-bottom: 30px;
}
.w-card-header{
    padding: .75rem 1.5rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #e3eaef;
    line-height: 1.1;
    margin: 10px 0;
    font-weight: 700;
    font-size: 16px;
    padding: 1.1428571429rem;
    margin-top: 0;
}
.w-card-block{
    flex: 1 1 auto;
    padding: 1.1428571429rem;
}
.w-card .row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.w-card .form-group{
    margin-bottom: 1rem;
}
.w-card label{
    font-weight: 600;
    display: inline-block;
    margin-bottom: .5rem;
}
.w-card input.form-control{
    height: calc(2.2125rem + 2px);
}
.w-card .form-control {
    display: block;
    width: 100%;
    padding: .45rem .9rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}
.w-card textarea.form-control{
    resize: none;
}
.w-card .form-check-inline{
    display: inline-block;
    position: relative;
    margin-bottom: .5rem;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem;
}
.w-card .form-check-inline .form-check-label {
    padding-left: 1.5rem;
    margin-bottom: 0;
    cursor: pointer;
    display: block;
    margin-top: .2857142857rem;
    font-weight: 600;
}
.w-card .form-check-input {
    position: absolute;
    margin-top: .35rem;
    margin-left: -1.5rem;
}
.link-data{
    cursor: pointer;
}
/* .separator{
    margin: 0.5rem 0;
    background-color: #d64427;
} */
.fis-offices h2.off-title{
    font-size: 1.2rem;
}
.fis-offices h4{
    font-size: .8rem;
    font-weight: bold;
}
.office-cover{
    display: flex;
}
.office-cover>div{
    flex: 1;
}
.office-cover div.img{
    padding-right: 1rem;
}
.office-cover img{
    border-radius: 7px;
}
.fis-offices .row{
    margin-right: 0;
    margin-left: 0;
}
.fis-offices .row .office-cover{
    padding-right: 0px;
    padding-left: 0px;
}
.form-error{
    color: red;
    font-size: .8rem;
    display: block;
    padding: .4rem 0;
}
.form-error.prod{
    color: #fff !important;
}
.w-card .form-control.yellow-bg {
    background-color: #eceb83;
}
.recaptcha-box{
    display: flex;
    justify-content: flex-end;
}
.recaptcha-box .cover{
    display: flex;
    align-items: center;
    position: relative;
}
.recaptcha-box .cover input{
    width: 4rem;
    line-height: 1;
    border: 1px solid #77aaff;
    padding: .2rem;
    border-radius: 4px;
    margin-right: 5px;
    text-align: center;
    font-weight: bold;
}
.recaptcha-box .cover div.cap-photo{
    position: relative;
    font-size: .8rem;
    letter-spacing: .5rem;
    padding: .5rem;
    text-align: center;
}
.recaptcha-box .cover div.cap-photo div.hide{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    background: rgba(0,0,0,.125);
}
.text-secondary.prod {
    color: #fff !important;
}
.text-secondary.prod a{
    color: #263ec8;
}
.mobile-app-discovery{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: #999;
    height: 300px;
}
.formError {
    display:block; padding: .3rem; font-size: .8rem; color: #e9ec08;
}
input[type="text"].selectallfocus:focus,
input[type="date"].selectallfocus:focus,
input[type="email"].selectallfocus:focus,
input[type="number"].selectallfocus:focus,
textarea.selectallfocus:focus{
    background-color: #eceb83;
}
.img-mobile-app{
    height: 500px;
    position: absolute;
}
.info-web.center{
    display: flex;
    align-items: center;
    justify-content: center;

}
.col-md-6.mobile-display{
    display: flex;
    align-items: center;
    justify-content: center;
}

.lst-btn-mobile{
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
}

.lst-btn-mobile li {
    display: inline-block;
    margin: 10px;
}

.lst-btn-mobile .download {
    width: 200px;
    height: 75px;
    background: black;
    float: left;
    border-radius: 5px;
    position: relative;
    color: #fff;
    cursor: pointer;
    border: 1px solid #fff;
}

.lst-btn-mobile .download > .fa {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    font-size: 2.5rem;
  }
  
  .lst-btn-mobile .df,
  .lst-btn-mobile .dfn {
    position: absolute;
    left: 70px;
  }
  
  .lst-btn-mobile .df {
    top: 20px;
    font-size: .68em;
  }
  
  .lst-btn-mobile .dfn {
    top: 33px;
    font-size: 1.08em;
  }
  
  .lst-btn-mobile .download:hover {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  .home-mobile .info-titles .card-portal{
    width: 100%;
  }